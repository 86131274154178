import React, { useEffect, useState } from "react";
import { graphql, Link } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";

import {filter} from 'lodash';

const Bibliography = props => {

    const language_id = 2;
    const bibliography = filter(props.data.allBibliography.nodes, function(item){ return item.language_id == language_id})[0];

    return (
        <>
            <Layout pathContext={props.pathContext}>
                <SEO title="Bibliographie" />


                {bibliography && (
                    <section className="bibliography_section">
                        <div className="bibliography_wrapper main_content_wrapper">
                            <h1>{bibliography.title}</h1>

                            <button className="go_back_btn link_btn">
                                <Link to="/stadtgeschichte">&lt; Zurück</Link>
                            </button>

                            <div
                                className="bibliography_text_wrapper"
                                dangerouslySetInnerHTML={{
                                    __html: bibliography.description
                                }}
                            ></div>

                        </div>
                    </section>

                )}
                
            </Layout>
        </>
    );
};

export default Bibliography;

export const query = graphql`
    query Bibliography {
        allBibliography {
            nodes {
                language_id
                title
                description
            }
        }
    }
`